import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { device } from "../utils/mixins"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageTopFullSizeImgCont,
  SectionContentContainer,
} from "../components/imageComponents"

import {
  PageSectionTitle,
  SectionTitleText,
  BodyText,
} from "../components/textComponents"

import { PageImageButton } from "../components/interactiveComponents"

var Image = styled(Img)`
  max-height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

function NotFoundPage() {
  var data = useStaticQuery(graphql`
    query {
      notFoundTopImg: file(
        relativePath: {
          eq: "i2O_Oil_and_Gas_Modernization_and_Optimization.jpeg"
        }
      ) {
        ...fluidImage
      }
    }
  `)
  return (
    <Layout>
      <SEO title="404: Not found" />
      <PageTopFullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.notFoundTopImg.sharp.fluid}
          alt="i2O - Compressed Natural Gas Application Specialists. Serving Canada and the United States"
        />
        <PageSectionTitle>Page Not Found</PageSectionTitle>
        <PageImageButton to="/contact-us/">Contact Us</PageImageButton>
      </PageTopFullSizeImgCont>

      <SectionContentContainer>
        <SectionTitleText>Somebody Hit the ESD...</SectionTitleText>
        <BodyText>
          We couldn't find the page that you were looking for. Please try again.
          Or select an item from the menu above.
        </BodyText>
      </SectionContentContainer>
    </Layout>
  )
}

export default NotFoundPage
